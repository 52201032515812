import { TunnelHelper } from "@/helpers/TunnelHelper.js";

export class Tunnel {
  paymentMethod;
  constructor(ctx, store) {
    this.context = ctx;
    this.store = store;
    this.cart = this.store.state.shop.cart;
    this.tunnelHelper = new TunnelHelper(
      this.context.$http,
      this.context.$auth,
      store
    );
  }
  set fixedNumber(val) {
    this.amount.fixedNumber = val;
  }
  get totalCart() {
    if (this.promoCode) {
      return {
        ht: this.amount.toFixed(
          this.totalProduct.promotion.ht +
            this.shipping.ht -
            this.totalProduct.promoCode.ht
        ),
        ttc: this.amount.toFixed(
          this.totalProduct.promotion.ttc +
            this.shipping.ttc -
            this.totalProduct.promoCode.ttc
        ),
        euroHT: this.amount.toFixed(
          this.totalProduct.promotion.euroHT +
            this.shipping.euroHT -
            this.totalProduct.promoCode.euroHT
        ),
        euroTTC: this.amount.toFixed(
          this.totalProduct.promotion.euroTTC +
            this.shipping.euroTTC -
            this.totalProduct.promoCode.euroTTC
        ),
      };
    }
    return {
      ht: this.amount.toFixed(
        this.totalProduct.promotion.ht + this.shipping.ht
      ),
      ttc: this.amount.toFixed(
        this.totalProduct.promotion.ttc + this.shipping.ttc
      ),
      euroHT: this.amount.toFixed(
        this.totalProduct.promotion.euroHT + this.shipping.euroHT
      ),
      euroTTC: this.amount.toFixed(
        this.totalProduct.promotion.euroTTC + this.shipping.euroTTC
      ),
    };
  }

  get totalProduct() {
    let regular = {
      ht: 0,
      ttc: 0,
      euroHT: 0,
      euroTTC: 0,
    };
    let promotion = {
      ht: 0,
      ttc: 0,
      euroHT: 0,
      euroTTC: 0,
    };
    const amount = this.amount;

    let codePromoTotal = 0;

    this.cart.listItemLocal.map((item) => {
      const qty = Number(item.quantity);
      const regularPrices = Number(
        item.product.Prices.RegularPrices[0].TaxExcl
      );
      let promotionPrices = regularPrices;

      if (item.product.Prices.InPromotion === "true") {
        promotionPrices = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      const amountRegularPrices = amount.price(regularPrices);
      regular.ht += qty * amountRegularPrices.ht;
      regular.ttc += qty * amountRegularPrices.ttc;
      regular.euroHT += qty * amountRegularPrices.euroHT;
      regular.euroTTC += qty * amountRegularPrices.euroTTC;
      regular.taxRate = amountRegularPrices.taxRate;
      regular.changeRate = amountRegularPrices.changeRate;

      const amountPromotionPrices = amount.price(promotionPrices);
      promotion.ht += qty * amountPromotionPrices.ht;
      promotion.ttc += qty * amountPromotionPrices.ttc;
      promotion.euroHT += qty * amountPromotionPrices.euroHT;
      promotion.euroTTC += qty * amountPromotionPrices.euroTTC;
      promotion.taxRate = amountPromotionPrices.taxRate;
      promotion.changeRate = amountPromotionPrices.changeRate;

      if (this.promoCode) {
        if (!this.promoCode.AllProducts) {
          if (this.promoCode.IdCartDetailList) {
            if (this.promoCode.IdCartDetailList.includes(item.idCartDetail)) {
              codePromoTotal += qty * amountPromotionPrices.ht;
            }
          }
        }
      }
    });

    regular.ht = amount.toFixed(regular.ht);
    regular.ttc = amount.toFixed(regular.ttc);
    regular.euroHT = amount.toFixed(regular.euroHT);
    regular.euroTTC = amount.toFixed(regular.euroTTC);

    promotion.ht = amount.toFixed(promotion.ht);
    promotion.ttc = amount.toFixed(promotion.ttc);
    promotion.euroHT = amount.toFixed(promotion.euroHT);
    promotion.euroTTC = amount.toFixed(promotion.euroTTC);

    if (this.promoCode) {
      if (this.promoCode.AllProducts) {
        codePromoTotal = promotion.euroHT;
      }
    }

    return {
      promotion: promotion,
      regular: regular,
      promoCode: this.getPromoCodeTotal(codePromoTotal),
    };
  }
  get totalProductPromotion() {
    const { cartTotal } = this.cart;
    let promotionPrice = 0;
    if (cartTotal.PromotionPrice) {
      promotionPrice = cartTotal.PromotionPrice;
    }
    const amount = this.amount;
    const total = amount.price(promotionPrice);

    return {
      ht: total.ht,
      ttc: total.ttc,
      euroHT: total.euroHT,
      euroTTC: total.euroTTC,
      taxRate: total.taxRate,
      changeRate: total.changeRate,
    };
  }

  get totalProductRegular() {
    const { cartTotal } = this.cart;
    let regularPrice = 0;
    if (cartTotal.RegularPrice) {
      regularPrice = cartTotal.RegularPrice;
    }

    const amount = this.amount;
    const total = amount.price(regularPrice);

    return {
      ht: total.ht,
      ttc: total.ttc,
      euroHT: total.euroHT,
      euroTTC: total.euroTTC,
      taxRate: total.taxRate,
      changeRate: total.changeRate,
    };
  }
  get shipping() {
    const carrier = this.tunnelHelper.getDeliveryOption();
    let totalShipping;
    const amount = this.amount;

    if (carrier) {
      totalShipping = amount.price(carrier.DeliveryFees, carrier.Tax.TaxRate);
    } else {
      totalShipping = amount.price(0, 0);
    }

    return {
      ht: totalShipping.ht,
      ttc: totalShipping.ttc,
      euroHT: totalShipping.euroHT,
      euroTTC: totalShipping.euroTTC,
      taxRate: totalShipping.taxRate,
      changeRate: totalShipping.changeRate,
    };
  }

  get productLength() {
    return this.cart.listItemLocal.length;
  }

  get shippingAddress() {
    return this.tunnelHelper.getCurrentAddress();
  }

  get invoiceAddress() {
    const address = this.tunnelHelper.getCurrentInvoiceAddress();
    if (address === null) {
      return this.shippingAddress;
    }
    return address;
  }

  get carrier() {
    return this.tunnelHelper.getDeliveryOption();
  }

  get custom_data() {
    const amount = this.amount;

    const discountHT = amount.toFixed(
      this.totalProduct.promotion.euroHT - this.totalProduct.regular.euroHT
    );
    const discountTTC = amount.toFixed(
      this.totalProduct.promotion.euroTTC - this.totalProduct.regular.euroTTC
    );

    const data = {
      IdCarrier: this.carrier.IdCarrier,
      IdAddressDelivery: this.shippingAddress.IdAddress,
      IdAddressInvoice: this.invoiceAddress.IdAddress,
      ConversionRate: amount.convertionRate,

      IdCurrency: amount.currency.IdCurrency,

      ProductTaxRate: this.totalProduct.promotion.taxRate,
      ShippingTaxRate: this.shipping.taxRate,

      TotalPaidTaxExcl: this.totalCart.euroHT,
      TotalPaidTaxIncl: this.totalCart.euroTTC,
      ShippingCostTaxExcl: this.shipping.euroHT,
      ShippingCostTaxIncl: this.shipping.euroTTC,

      TotalDiscountTaxExcl: discountHT,
      TotalDiscountTaxIncl: discountTTC,
      DiscountName: "Promotion",

      TotalProductTaxExcl: this.totalProduct.promotion.euroHT,
      TotalProductTaxIncl: this.totalProduct.promotion.euroTTC,
      IdCustomer: this.context.$auth.user.Customer.Id,
      IdCart: this.cart.idCart,
      Cart: JSON.stringify(this.custom_data_cart),
      IdPayment: this.paymentMethod ? this.paymentMethod.IdPayment : 0,
    };

    if (this.promoCode) {
      data.PromoCode = this.promoCode.code;
      data.UsesPromoCode = "true";
    } else {
      data.UsesPromoCode = "false";
    }

    return data;
  }

  get custom_data_cart() {
    const cart = [];
    const amount = this.amount;

    let ht = 0;
    let ttc = 0;

    this.cart.listItemLocal.map((item) => {
      let productPrice = item.product.Prices.RegularPrices[0].TaxExcl;

      if (item.product.Prices.InPromotion === "true") {
        productPrice = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      const price = amount.price(productPrice);

      cart.push({
        Reference: item.product.Reference,
        IdProduct: item.product.IdProduct,
        Color: item.color,
        Size: item.size,
        Quantity: item.quantity,
        PriceTaxExcl: price.euroHT,
        PriceTaxIncl: price.euroTTC,
        EAN13: item.product.EAN13,
        IdProductAttribute: item.product.IdProductAttribute,
      });
      ht += price.euroHT;
      ttc += price.euroTTC;
    });
    return cart;
  }

  productTitle(ProductDescription) {
    const i18n = this.context.$i18n;
    try {
      if (i18n.locale === "en") {
        return ProductDescription[1].Title;
      }
      if (i18n.locale === "de") {
        return ProductDescription[2].Title;
      }
      return ProductDescription[0].Title;
    } catch (error) {}
    return "Product";
  }

  get basket() {
    const basket = [];
    const amount = this.amount;

    this.cart.listItemLocal.map((item) => {
      const quantity = item.quantity;
      let productPriceRegular = item.product.Prices.RegularPrices[0].TaxExcl;
      let productPromotionPrice = productPriceRegular;

      if (item.product.Prices.InPromotion === "true") {
        productPromotionPrice = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      const priceRegular = amount.price(productPriceRegular);
      const pricePromotion = amount.price(productPromotionPrice);

      const discount =
        (pricePromotion.euroTTC - priceRegular.euroTTC) * quantity;

      const newItem = {
        product_reference: item.product.Reference + "--" + item.idCartDetail,
        name: this.productTitle(item.product.ProductDescription),
        type: "good",
        quantity: Number(quantity),
        unit_price: priceRegular.euroTTC,
        discount: discount,
        tax_rate: pricePromotion.taxRate,
        total_amount: pricePromotion.euroTTC * quantity,
      };
      basket.push(newItem);
    });

    const carrier = this.tunnelHelper.getDeliveryOption();
    if (carrier) {
      const shippingRef = carrier.CarrierType + "-" + carrier.IdCarrier;
      basket.push({
        product_reference: shippingRef,
        name: carrier.CarrierName,
        type: "fee",
        quantity: 1,
        unit_price: this.shipping.euroTTC,
        discount: 0,
        tax_rate: this.shipping.taxRate,
        total_amount: this.shipping.euroTTC,
      });
    }

    if (this.promoCode) {
      basket.push({
        product_reference: this.promoCode.PromoCodeTypeName,
        name: this.promoCode.PromoCodeTypeName,
        type: "discount",
        quantity: 1,
        unit_price: 0,
        discount: -1 * this.totalProduct.promoCode.euroTTC,
        tax_rate: "0",
        total_amount: -1 * this.totalProduct.promoCode.euroTTC,
      });
    }

    return basket; // price+(price * 0.2)
  }

  get basketKlarna() {
    const basket = [];
    const amount = this.amount;

    let orderTaxAmount = 0;

    this.cart.listItemLocal.map((item) => {
      const quantity = item.quantity;
      let productPriceRegular = item.product.Prices.RegularPrices[0].TaxExcl;
      let productPromotionPrice = productPriceRegular;

      if (item.product.Prices.InPromotion === "true") {
        productPromotionPrice = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      const priceRegular = amount.price(productPriceRegular);
      const pricePromotion = amount.price(productPromotionPrice);

      const discount =
        (priceRegular.euroTTC - pricePromotion.euroTTC) * quantity;

      const totalAmount = pricePromotion.euroHT * quantity;
      const totalTaxAmount = amount.price(totalAmount * pricePromotion.taxRate);

      // 1euro = 100
      const taxRate = pricePromotion.taxRate * 10000; // 0.2 => 20% = 2000
      const total_amount = amount.price(totalAmount).euroTTC * 100;
      const total_tax_amount =
        total_amount - (total_amount * 10000) / (10000 + taxRate);

      const newItem = {
        type: "physical",
        reference: item.product.Reference + "--" + item.idCartDetail,
        name: this.productTitle(item.product.ProductDescription),
        quantity: Number(quantity),

        unit_price: priceRegular.euroTTC * 100,
        tax_rate: taxRate,
        total_amount: Number(total_amount.toFixed(2)),
        total_discount_amount: Number(discount.toFixed(2)) * 100,
        total_tax_amount: Number(total_tax_amount.toFixed(0)),

        // "image_url": "https://www.exampleobjects.com/logo.png",
        // "product_url": "https://www.estore.com/products/f2a8d7e34"
      };

      orderTaxAmount += total_tax_amount;

      basket.push(newItem);
    });

    const carrier = this.tunnelHelper.getDeliveryOption();
    if (carrier) {
      const shippingRef = carrier.CarrierType + "-" + carrier.IdCarrier;

      // const totalAmount = this.shipping.euroHT * 1;
      // const totalTaxAmount = amount.price(totalAmount * this.shipping.taxRate);

      const taxRate = this.shipping.taxRate * 10000; // 0.2 => 20% = 2000
      const total_amount = this.shipping.euroTTC * 100;
      const total_tax_amount =
        total_amount - (total_amount * 10000) / (10000 + taxRate);

      basket.push({
        type: "shipping_fee",
        reference: shippingRef,
        name: carrier.CarrierName,
        quantity: 1,
        unit_price: this.shipping.euroTTC * 100,
        tax_rate: taxRate, // 0.2 => 20% = 2000
        total_amount: total_amount,
        total_discount_amount: 0,
        total_tax_amount: Number(total_tax_amount.toFixed(2)),
      });

      orderTaxAmount += Number(total_tax_amount.toFixed(2));
    }

    if (this.promoCode) {
      basket.push({
        type: "discount",
        reference: this.promoCode.PromoCodeTypeName,
        name: this.promoCode.PromoCodeTypeName,
        quantity: 1,
        unit_price: -1 * this.totalProduct.promoCode.euroTTC * 100,
        tax_rate: 0,
        total_amount: -1 * this.totalProduct.promoCode.euroTTC * 100,
        total_discount_amount: 0,
        total_tax_amount: 0,
      });
    }

    return { basket, orderTaxAmount }; // price+(price * 0.2)
  }

  get purchaseItems() {
    const items = [];
    const amount = this.amount;

    this.cart.listItemLocal.map((item) => {
      let productPrice = item.product.Prices.RegularPrices[0].TaxExcl;

      if (item.product.Prices.InPromotion === "true") {
        productPrice = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      const price = amount.price(productPrice);
      items.push({
        item_id: item.product.IdProduct,
        item_name: this.productTitle(item.product.ProductDescription),
        price: price.euroTTC,
        quantity: item.quantity,
      });
    });
    return items;
  }

  //a voire
  get totalProduct2() {
    const amount = this.amount;
    const { listItemLocal } = this.cart;
    let totalRugulare = 0,
      totalPromotion = 0;

    listItemLocal.map(function (item) {
      const rugular = item.product.Prices.RegularPrices[0].TaxExcl;
      let promotion = null;
      const quantity = Number(item.quantity);

      if (item.product.Prices.InPromotion === "true") {
        promotion = item.product.Prices.PromotionPrices[0].TaxExcl;
      }

      if (promotion === null) {
        promotion = rugular;
      }
      const priceRugular = amount.price(rugular);
      const pricePromotion = amount.price(promotion);

      totalRugulare += priceRugular.ht * quantity;
      totalPromotion += pricePromotion.ht * quantity;
    });
    const totalPriceRugular = amount.price(totalRugulare);
    const totalPricePromotion = amount.price(totalPromotion);

    return {
      regular: {
        ht: totalPriceRugular.ht,
        ttc: totalPriceRugular.ttc,
        euroHT: totalPriceRugular.euroHT,
        euroTTC: totalPriceRugular.euroTTC,
        taxRate: totalPriceRugular.taxRate,
        changeRate: totalPriceRugular.changeRate,
      },
      promotion: {
        ht: totalPricePromotion.ht,
        ttc: totalPricePromotion.ttc,
        euroHT: totalPricePromotion.euroHT,
        euroTTC: totalPricePromotion.euroTTC,
        taxRate: totalPricePromotion.taxRate,
        changeRate: totalPricePromotion.changeRate,
      },
    };
  }

  //Promo code
  get promoCode() {
    return this.cart.promoCode;
  }

  getPromoCodeTotal(promotionEuroHT) {
    if (!this.promoCode) return null;

    const amount = this.amount;

    if (this.promoCode.PromoCodeTypeName === "percentage") {
      const codePromoTotal = amount.toFixed(
        (Number(this.promoCode.ReductionValue) / 100) * promotionEuroHT
      );
      const codePromoPrices = amount.price(codePromoTotal);

      return codePromoPrices;
    } else {
      if (promotionEuroHT === 0) {
        const codePromoPrices = amount.price(0);
        return codePromoPrices;
      }
      const codePromoTotal = amount.toFixed(
        Number(this.promoCode.ReductionValue)
      );
      const codePromoPrices = amount.price(codePromoTotal);

      return codePromoPrices;
    }
  }
}
