export class ShopSettings {
  constructor(http, store) {
    this.http = http;
    this.store = store;
  }

  async getSettingWithKey(key) {
    try {
      const res = await this.http.API_AUTH.$get(
        `shop/setting?SettingKey=${key}`
      );

      if (res.SettingValue) {
        this.store.commit("shop/settings/init", res);
      }
      return res;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
